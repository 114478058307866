.m-2 {
  margin: 0.5rem; /* 8px */
}

.m-4 {
  margin: 1rem; /* 16px */
}

.m-6 {
  margin: 1.5rem; /* 24px */
}

.m-12 {
  margin: 3rem; /* 48px */
}

.ml-2 {
  margin-left: 0.5rem; /* 8px */
}

.ml-4 {
  margin-left: 1rem; /* 16px */
}

.ml-6 {
  margin-left: 1.5rem; /* 24px */
}

.ml-12 {
  margin-left: 3rem; /* 48px */
}

.mr-2 {
  margin-right: 0.5rem; /* 8px */
}

.mr-4 {
  margin-right: 1rem; /* 16px */
}

.mr-6 {
  margin-right: 1.5rem; /* 24px */
}

.mr-12 {
  margin-right: 3rem; /* 48px */
}

.mt-2 {
  margin-top: 0.5rem; /* 8px */
}

.mt-4 {
  margin-top: 1rem; /* 16px */
}

.mt-6 {
  margin-top: 1.5rem; /* 24px */
}

.mt-12 {
  margin-top: 3rem; /* 48px */
}

.mb-2 {
  margin-bottom: 0.5rem; /* 8px */
}

.mb-4 {
  margin-bottom: 1rem; /* 16px */
}

.mb-6 {
  margin-bottom: 1.5rem; /* 24px */
}

.mb-12 {
  margin-bottom: 3rem; /* 48px */
}

.m-auto {
  margin: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}
