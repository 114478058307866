@import "colors";

.border-primary {
  border: 1px solid $primary;
}

.border-gray-dark {
  border: 1px solid $gray-dark;
}

.border-t-gray-dark {
  border-top: 0.5px solid $gray-dark;
}

.border-b-gray-dark {
  border-bottom: 0.5px solid $gray-dark;
}

.border-none {
  border: none;
}
