@import "colors";

.background-primary {
  background-color: $primary;
}

.background-gray-base {
  background-color: $gray-base;
}

.background-white {
  background-color: $white;
}

.bg-transparent {
  background-color: transparent;
}
