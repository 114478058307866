/* font */

.font-regular {
  font-family: "Open Sans";
  font-weight: 400;
  line-height: 1.5;
}

.font-bold {
  font-family: "Open Sans";
  font-weight: 600;
  line-height: 1.5;
}
