@import "colors";

.loader {
  border-radius: 50%;
  animation: spin 2s linear infinite;
  width: 120px;
  height: 120px;
  border: 16px solid $gray-base;
  border-top: 16px solid $primary;
}

.loaderSmall {
  width: 22px;
  height: 22px;
  border: 5px solid $gray-base;
  border-top: 5px solid $primary;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
