.p-2 {
  padding: 0.5rem; /* 8px */
}

.p-4 {
  padding: 1rem; /* 16px */
}

.p-6 {
  padding: 1.5rem; /* 24px */
}

.p-12 {
  padding: 3rem; /* 48px */
}

.pl-2 {
  padding-left: 0.5rem; /* 8px */
}

.pl-4 {
  padding-left: 1rem; /* 16px */
}

.pl-6 {
  padding-left: 1.5rem; /* 24px */
}

.pl-12 {
  padding-left: 3rem; /* 48px */
}

.pr-2 {
  padding-right: 0.5rem; /* 8px */
}

.pr-4 {
  padding-right: 1rem; /* 16px */
}

.pr-6 {
  padding-right: 1.5rem; /* 24px */
}

.pr-12 {
  padding-right: 3rem; /* 48px */
}

.pt-2 {
  padding-top: 0.5rem; /* 8px */
}

.pt-4 {
  padding-top: 1rem; /* 16px */
}

.pt-6 {
  padding-top: 1.5rem; /* 24px */
}

.pt-12 {
  padding-top: 3rem; /* 48px */
}

.pb-2 {
  padding-bottom: 0.5rem; /* 8px */
}

.pb-4 {
  padding-bottom: 1rem; /* 16px */
}

.pb-6 {
  padding-bottom: 1.5rem; /* 24px */
}

.pb-12 {
  padding-bottom: 3rem; /* 48px */
}
