/* grid */

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid {
  display: grid;
}

.gap-2 {
  gap: 0.5rem; /* 8px */
}

.gap-4 {
  gap: 1rem; /* 16px */
}

.gap-6 {
  gap: 1.5rem; /* 24px */
}

.gap-x-12 {
  column-gap: 3rem; /* 48px */
}
