.h-5 {
  height: 1.25rem; /* 20px */
}

.h-8 {
  height: 2rem; /* 32px */
}

.h-6 {
  height: 1.5rem; /* 24px */
}

.h-12 {
  height: 3rem; /* 48px */
}

.h-full {
  height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}
