.w-5 {
  height: 1.25rem; /* 20px */
}

.w-8 {
  width: 2rem; /* 32px */
}

.w-6 {
  width: 1.5rem; /* 24px */
}

.w-12 {
  width: 3rem; /* 48px */
}
