.rounded {
  border-radius: 0.125rem; /* 2px */
}

.rounded-lg {
  border-radius: 0.5rem; /* 8px */
}

.rounded-full {
  border-radius: 9999px;
}
