/* flex */
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.space-between {
  justify-content: space-between;
}

.content-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}
