@import "colors";

.text-center {
  text-align: center;
}

.text-sm {
  font-size: 0.75rem; /* 12px */
}

.text-base {
  font-size: 1rem; /* 16px */
}

.text-lg {
  font-size: 1.5rem; /* 24px */
}

.text-primary {
  color: $primary;
}

.text-black {
  color: $black;
}

.text-white {
  color: $white;
}

.text-gray-light {
  color: $gray-light;
}

.text-gray-base {
  color: $gray-base;
}

.text-gray-dark {
  color: $gray-dark;
}

.text-decoration-none {
  text-decoration: none;
}

.text-inherit {
  color: inherit;
}
